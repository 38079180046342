import { TableBody, TableRow, Box, Table } from '@mui/material';
import { Button, H4, Text } from '@digitalportal-ui/core';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import {
  Wrapper,
  StyledTable,
  StyledTableCell,
  SummaryBodyWrapper,
  StyledPriceTypography,
  Divider,
  PerMonthWrapper,
} from './styles';
import GroupSwitch from '../GroupSwitch/GroupSwitch';
import { QuoteStatus } from '../../../redux/slices/quote/quote.types';
import animationData from '../../../assets/animationsjsons/calculator.json';

export type SummaryCardProps = {
  quote: {
    monthlyPremium: number;
    annualPremium: number;
    monthlyTotalContractPrice: number;
    annualTotalContractPrice: number;
    monthlyTaxPremium: number;
    annualTaxPremium: number;
    switchValues: Array<{
      title: string;
      subtitle: string;
      checked?: boolean;
    }>;
    quoteStatus: QuoteStatus;
  };
  onSwitchChange: () => void;
  loading: boolean;
  submitButtonHandler: () => void;
  incentive?: string;
};

function SummaryCard({
  quote,
  onSwitchChange,
  loading,
  submitButtonHandler,
  incentive,
}: SummaryCardProps): React.JSX.Element {
  const annuallyChosen = quote.switchValues[1].checked;
  const chosenPrice = annuallyChosen ? quote.annualPremium : quote.monthlyPremium;
  const totalContractPrice = annuallyChosen ? quote.annualTotalContractPrice : quote.monthlyTotalContractPrice;
  const [incentiveText, setIncentiveText] = useState('');

  useEffect(() => {
    if (incentive === '2MonthsFree') {
      setIncentiveText('Includes your 2 months free offer');
    }
  }, []);

  return (
    <Wrapper data-testid="summaryCard">
      <GroupSwitch onSwitchChange={onSwitchChange} switchValues={quote.switchValues} />
      <>
        <SummaryBodyWrapper>
          <Box display="flex" flexDirection="row">
            <StyledPriceTypography data-testid="summaryCardPrice" variant="h1">
              {loading ? (
                <Lottie
                  data-testid="calculator-animation"
                  loop
                  autoplay
                  animationData={animationData}
                  style={{ width: '50px' }}
                />
              ) : (
                `£${Number(chosenPrice).toFixed(2)}`
              )}
            </StyledPriceTypography>
          </Box>
          <PerMonthWrapper>
            <H4 semibold>total payment per {annuallyChosen ? ' year' : ' month'}</H4>
          </PerMonthWrapper>
        </SummaryBodyWrapper>
        <Divider />
        <StyledTable $quotePurchased={quote.quoteStatus === 'Purchased'} data-testid="summaryCardTable">
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell data-testid="total-contract-price-text">Total for the year</StyledTableCell>
                <StyledTableCell data-testid="total-contract-price-price" $right>
                  {loading ? (
                    <Lottie
                      data-testid="calculator-animation"
                      loop
                      autoplay
                      animationData={animationData}
                      style={{ width: '25px' }}
                    />
                  ) : (
                    `£${Number(totalContractPrice).toFixed(2)}`
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          {incentiveText !== '' && (
            <Text data-testid="incentive-text" mb={2} bold>
              {incentiveText}
            </Text>
          )}
          <Text>This includes insurance premium tax at the current rate.</Text>
        </StyledTable>
      </>
      {quote.quoteStatus !== 'Purchased' && (
        <Button
          disabled={loading}
          data-testid="summarySubmitBtn"
          fullWidth
          color="secondary"
          size="large"
          onClick={submitButtonHandler}
          style={{ whiteSpace: 'nowrap' }}
        >
          {quote.quoteStatus === 'Expired' ? 'Change start date' : "I'm happy to go ahead and pay"}
        </Button>
      )}
    </Wrapper>
  );
}
export default SummaryCard;
