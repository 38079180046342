import { colors, Text } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import RadioVariants from '../../../../enums/radioVariants';
import { AddressType } from '../../../../redux/slices/quote';
import RadioBlock from '../../../molecules/RadioBlock/RadioBlock';
import AutoCompleteAddress from '../../AutoCompleteAddress/AutoCompleteAddress';
import { RadioContainer } from '../styles';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';

type BillingAddressPreferenceProps = {
  isPolicyHolder: boolean;
  showError: boolean | undefined;
  overlayDisplayed: boolean;
  setFieldValue: (field: string, value: boolean | AddressType | string | null, shouldValidate?: boolean) => void;
  initialValues: AddressType;
  addressFieldsVisible: boolean;
};

export default function BillingAddressPreference({
  isPolicyHolder,
  showError,
  overlayDisplayed,
  setFieldValue,
  initialValues,
  addressFieldsVisible,
}: BillingAddressPreferenceProps): React.JSX.Element {
  return (
    <>
      <QuestionHeader title="Is the address for the account the same as the address you gave earlier?" testId="same-address-question" />
      <RadioContainer sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Box onClick={() => setFieldValue('manualPolicyHolderBilling', false)}>
          <RadioBlock
            dataTestId="radioButton-sameAddress-yes"
            variant={RadioVariants.dot}
            name="sameAddress"
            isErrored={showError && !overlayDisplayed}
            value={0}
            checkedColor={colors.oceanBlue}
            tabIndex={overlayDisplayed ? -1 : 0}
          >
            <Text>Yes</Text>
          </RadioBlock>
        </Box>
        <Box onClick={() => setFieldValue('manualPolicyHolderBilling', true)}>
          <RadioBlock
            dataTestId="radioButton-sameAddress-no"
            variant={RadioVariants.dot}
            name="sameAddress"
            isErrored={showError && !overlayDisplayed}
            value={1}
            checkedColor={colors.oceanBlue}
            tabIndex={overlayDisplayed ? -1 : 0}
          >
            <Text>No</Text>
          </RadioBlock>
        </Box>
      </RadioContainer>

      {isPolicyHolder && (
        <AutoCompleteAddress
          name="autocomplete"
          setFieldValue={setFieldValue}
          values={initialValues}
          addressFieldsVisible={addressFieldsVisible}
        />
      )}
    </>
  );
}
