enum RadioVariants {
  rowWide = 'ROWFULLWIDTH',
  row = 'ROW',
  card = 'CARD',
  dot = 'DOT',
  brick = 'BRICK',
  columnWithAdvert = 'WITH_ADVERTISMENT',
}

export default RadioVariants;
