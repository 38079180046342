import { Box, CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { Member } from '../../../redux/slices/quote';
import {
  PersonalDetailsContainer,
  NameContainer,
  DOBContainer,
  PostCodeContainer,
  DobPostcodeWrapper,
  EmailContainer,
  MobileContainer,
  SeparatorContainer,
  LinkStyle,
  DownloadIcon,
  DownloadLink,
} from './styles';

export type AddMemberDetailsHeaderProps = {
  policyHolder: boolean;
  memberDetailsProps: {
    id: string;
    firstname: string;
    lastname: string;
    dob: {
      day: string;
      month: string;
      year: string;
    };
    postcode: string;
    email: string;
    phone: string;
  };
  expanded?: boolean;
  ipidHandler?: (memberDetails : Partial<Member>, setLoading : Dispatch<SetStateAction<boolean>>) => void;
};

const AddMemberDetailsHeader = ({
  policyHolder,
  memberDetailsProps,
  expanded,
  ipidHandler,
}: AddMemberDetailsHeaderProps): React.JSX.Element => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  return (
    <Box flexDirection="row" data-testid="addMemberCardBox">
      <NameContainer className="axa-mask" bold data-testid="addMemberName" $expanded={expanded}>
        {`${memberDetailsProps?.firstname} ${memberDetailsProps?.lastname} ${policyHolder === true ? '(you)' : ''}`}
      </NameContainer>
      <PersonalDetailsContainer whiteSpace="pre" data-testid="addMemberDetailContainer">
        <DobPostcodeWrapper>
          <DOBContainer className="axa-mask" data-testid="addMemberDob">
            {dayjs(
              `${memberDetailsProps?.dob?.year}-${memberDetailsProps?.dob?.month}-${memberDetailsProps?.dob?.day}`).format('Do MMMM YYYY')}
          </DOBContainer>
          <PostCodeContainer className="axa-mask" data-testid="addMemberPostcode">
            {memberDetailsProps?.postcode && (
              <SeparatorContainer data-testid="postcodeDot">&middot;</SeparatorContainer>
            )}
            {memberDetailsProps?.postcode}
          </PostCodeContainer>
        </DobPostcodeWrapper>
        <EmailContainer className="axa-mask" data-testid="addMemberEmail">{memberDetailsProps?.email}</EmailContainer>
        <MobileContainer className="axa-mask" data-testid="addMemberMobile">
          {memberDetailsProps?.phone && <SeparatorContainer data-testid="mobileDot">&middot;</SeparatorContainer>}
          {memberDetailsProps?.phone}
        </MobileContainer>
      </PersonalDetailsContainer>
      {pathname === '/quote-summary' && (
        <DownloadLink
          data-testid={`${memberDetailsProps?.firstname}-${memberDetailsProps?.lastname}-ipid-link-wrapper`}
          onClick={() => {
            if (ipidHandler && !loading) {
              setLoading(true);
              ipidHandler(memberDetailsProps, setLoading);
            }
          }}
        >
          <LinkStyle
            data-testid={`${memberDetailsProps?.firstname}-${memberDetailsProps?.lastname}-ipid-link`}
            aria-label="Insurance product information"
          >
            Information about what we do and don’t cover
            {loading ? <CircularProgress data-testid={`${memberDetailsProps?.firstname}-${memberDetailsProps?.lastname}-ipid-loader`} color="inherit" size={20} /> : <DownloadIcon />}
          </LinkStyle>
        </DownloadLink>
      )}
    </Box>
  );
};

export default AddMemberDetailsHeader;
