import styled from 'styled-components';
import { Box, List } from '@mui/material';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import { Button, Text, colors, theme } from '@digitalportal-ui/core';
import { Link } from 'react-router-dom';
import UkMapImg from '../../../assets/svg/uk_map.svg?react';

export const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 180px;
  }
  ${theme.breakpoints.up('xl')} {
    margin-bottom: 240px;
  }
`;

export const PageIntroductionWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 690px;
    margin-bottom: 4px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 770px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const NetworkContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    width: 690px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const NetworkWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const RadioBlockContainer = styled(Box)`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.breakpoints.down('lg')} {
    margin-bottom: 32px;
  }
  
  ${theme.breakpoints.down('md')} {
    margin-bottom: 16px;
  }
`;

export const RadioBlockWrapper = styled(Box)`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .MuiInputLabel-root {
    align-items: flex-start;
  }
  .MuiRadio-root {
    padding: 2px 15px 9px 0;
  }
  .MuiPaper-root {
    align-items: flex-start;
    height: inherit;
    padding: 14px;
  }
  ${theme.breakpoints.up('md')} {
    .MuiPaper-root {
      padding: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const RadioContent = styled(Box)`
  text-align: left;
  white-space: pre-line;
`;

export const NetworkOptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    align-items: flex-start;
  }

  ${theme.breakpoints.up('lg')} {
    flex-direction: column;
  }
`;

export const PostcodeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  z-index: 1;

  ${theme.breakpoints.up('md')} {
    height: 50px;
    align-items: flex-start;
  }

  ${theme.breakpoints.up('lg')} {
    align-items: flex-start;
    width: max-content;
  }

  ${theme.breakpoints.up('xl')} {
    align-items: center;
    flex-direction: row;
    width: max-content;
  }
`;

export const MapContainer = styled(Box)<{
  $showPostcodeField: boolean;
  $postcodeErrored: boolean;
  $hasMapContext: boolean;
}>`
  ${theme.breakpoints.up('md')} {
    margin-top: ${({ $postcodeErrored, $hasMapContext }) => {
      if ($hasMapContext) {
        return $postcodeErrored ? '-94px' : '-66px';
      }
      return '';
    }};
  }

  ${theme.breakpoints.up('lg')} {
    width: 50%;
    margin-top: ${({ $showPostcodeField, $hasMapContext }) => {
      if ($hasMapContext) {
        return $showPostcodeField ? '54px' : '0';
      }
      return $showPostcodeField ? '102px' : '50px';
    }};
  }
`;

export const UkMap = styled(UkMapImg)`
  height: auto;
  width: 100%;
  margin-top: 8px;
`;

export const SearchPostcodeContainer = styled(Box)`
  width: 100%;
  margin-bottom: 16px;

  ${theme.breakpoints.only('md')} {
    width: 447px;
  }
`;

export const SearchButton = styled(Button)`
  width: fit-content;

  ${theme.breakpoints.down('md')} {
    height: 44px;
  }
`;

export const PostcodeFieldWrapper = styled(Box)`
  width: 100%;

  ${theme.breakpoints.up('md')} {
    width: 336px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 100%;
  }
`;

export const NetworkContentList = styled(List)`
  margin: 0 auto 8px 12px;
  color: ${colors.darkGrey};

  .MuiListItem-root {
    display: block;
    margin-bottom: 2px;
    padding-top: 0;
    padding-bottom: 0;
  }
  > * {
    a,
    div {
      display: inline-block;
    }
    &:after {
      content: '•';
      top: 0;
      left: 0;
      position: absolute;
      width: 10px;
      height: 10px;
    }
  }
`;

export const PhinLinkIcon = styled(LocalHospitalOutlinedIcon)`
  margin-right: 5px;
  color: ${colors.oceanBlueDark};
  ${theme.breakpoints.up('md')} {
    margin-right: 10px;
  }
`;

export const CustomText = styled(Text)<{
  $isBulletPoint: boolean;
  $isNewLine: boolean;
}>`
  margin-bottom: ${({ $isNewLine }) => ($isNewLine ? '8px' : '0')};
  margin-left: ${({ $isBulletPoint }) => ($isBulletPoint ? '24px' : '0')};
  display: ${({ $isBulletPoint }) => ($isBulletPoint ? 'list-item' : 'block')};
`;

export const PhinWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const PhinText = styled(Text)`
  margin-top: '16px';

  ${theme.breakpoints.up('md')} {
    margin-top: 0;
    margin-left: 16px;
  }
`;

export const ModalPhinLink = styled(Link)`
  color: ${colors.oceanBlue300};
`;

export const ModalStyledList = styled(List)`
  margin-bottom: 12px;
  .MuiListItem-root {
    margin-bottom: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
  > * {
    &:after {
      content: '•';
      top: 0;
      position: absolute;
      margin-left: -12px;
      width: 10px;
      height: 10px;
    }
  }
`;

export const PanelContainer = styled(Box)`
  ${theme.breakpoints.up('lg')} {
    width: 50%;
  }
`;
