import styled from 'styled-components';
import { Box, Card, Grid } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const CombinedFields = styled(Box)`
  display: flex;
  > * {
    width: 100%;
    &:first-child {
      margin-right: 32px;
    }
  }
`;

export const TitleWrapper = styled(Box)`
  margin-bottom: 32px;
`;

export const Wrapper = styled(Card)`
  text-align: left;
  padding: 32px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 2px 4px 0px #0000000f;
  width: 100%;

  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
`;

export const CardsIconsWrapper = styled(Grid)`
  padding-right: 12px;
  > * {
    width: 42px;
    height: 27px;
  }
`;

export const IframeWrapper = styled('iframe')`
  width: 305px;
  height: 790px;
  margin-top: -16px;
  ${theme.breakpoints.up('md')} {
    width: 705px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 620px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 740px;
  }
`;
