import { Box } from '@mui/material';
import { H3 } from '@digitalportal-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClockIcon from '../../../assets/svg/real_time_clock.svg?react';
import {
  PriceCardStyle,
  IconHolder,
  TitleHolder,
  EndTextHolder,
  StyledTypography,
  StyledLinkMobile,
  StyledLink,
  DescriptionWrapper,
  CheckIconStyled,
  CrossIconStyled,
} from './styles';
import { updateModalInfo } from '../../../redux/slices/helpers';
import { EntitlementModalContent } from '../../../redux/slices/helpers/helpers.types';
import EntitlementContentModal from '../EntitlementContentModal/EntitlementContentModal';
import { TStore } from '../../../redux/store';

export type PriceCardSmallProps = {
  title: string;
  selected?: boolean;
  entitlementDetails: EntitlementModalContent;
  annualPremium?: number;
  monthlyPremium?: number;
  paymentFrequency?: boolean;
};

export default function PriceCardSmall({
  title,
  selected,
  entitlementDetails,
  monthlyPremium,
  paymentFrequency,
  annualPremium,
}: PriceCardSmallProps): React.JSX.Element {
  const [diagnosisModal, setDiagnosisModal] = useState(false);
  const dispatch = useDispatch();

  const quoteRetrieved = useSelector((state: TStore) => state.helpersState.helpers.quoteRetrieved);

  return (
    <PriceCardStyle data-testid="priceCardSmall">
      <Box display="flex" flexDirection="row" width="100%">
        <IconHolder $isSelected={selected}>
          <ClockIcon />
          {selected ? <CheckIconStyled data-testid="check-icon" /> : <CrossIconStyled data-testid="cross-icon" />}
        </IconHolder>
        <DescriptionWrapper>
          <TitleHolder>
            <H3 bold>{title}</H3>
            <StyledLink
              data-testid={`view-more-details-${title.replace(/\s+/g, '-').toLowerCase()}`}
              onClick={() => {
                setDiagnosisModal(true);
                dispatch(updateModalInfo({ isModalOpen: true, modalTitle: title }));
              }}
            >
              Find out more
            </StyledLink>
            <EntitlementContentModal
              title={title}
              includedPoints={entitlementDetails.included}
              excludedPoints={entitlementDetails.excluded}
              openModal={diagnosisModal}
              setOpenModal={setDiagnosisModal}
            />
          </TitleHolder>
          {selected && (
            <Box display="flex" flexDirection="column" style={{ textAlign: 'left', justifyContent: 'center' }}>
              <EndTextHolder>
                <H3 bold>£{Number(paymentFrequency ? annualPremium : monthlyPremium).toFixed(2)}</H3>
                <StyledTypography>{paymentFrequency ? 'per year' : 'per month'}</StyledTypography>
              </EndTextHolder>
              {quoteRetrieved && <StyledTypography>excluding tax</StyledTypography>}
            </Box>
          )}
        </DescriptionWrapper>
      </Box>
      <StyledLinkMobile
        onClick={() => {
          setDiagnosisModal(true);
          dispatch(updateModalInfo({ isModalOpen: true, modalTitle: title }));
        }}
      >
        Find out more
      </StyledLinkMobile>
    </PriceCardStyle>
  );
}
