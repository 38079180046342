import styled from 'styled-components';
import { Paper, InputLabel } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const WrapperBase = styled(Paper)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  box-shadow: none;
  cursor: pointer;
  position: relative;
  display: flex;
  border-style: solid;
  border-color: ${({ $ischecked, $iserrored }) => {
    if ($iserrored) {
      return colors.shyTomato;
    }
    if ($ischecked) {
      return colors.oceanBlue;
    }
    return colors.grey;
  }};
  border-width: ${({ $ischecked, $iserrored }) => ($ischecked || $iserrored ? '2px' : '1px')};
`;

export const WrapperRow = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  &:last-child {
    margin-bottom: 0px;
  }
  margin-bottom: 20px;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.15);
  ${theme.breakpoints.up('xs')} {
    padding: 16px;
  }
  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

export const WrapperBrick = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  box-shadow: 0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: ${({ $ischecked }) => ($ischecked ? '0' : '1px')};
  align-items: center;
  border-radius: 6px;
  padding-left: ${({ $ischecked }) => ($ischecked ? '8px' : '9px')};
  padding-right: ${({ $ischecked }) => ($ischecked ? '8px' : '9px')};

  ${theme.breakpoints.up('md')} {
    height: 50px;
    padding-left: ${({ $ischecked, $disablePadding }) => ($ischecked && !$disablePadding ? '24px' : !$disablePadding && '25px')};
  }
`;

export const WrapperColumnWithAdvert = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  margin-bottom: 20px;
  padding: ${({ $ischecked }) => (!$ischecked ? '0 1px 0 1px' : '')};
  height: 250px;
`;

export const WrapperRowWide = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  &:last-child {
    margin-bottom: 0px;
  }
  margin-bottom: 20px;
  padding: 8px;
  min-height: 75px;
  width: 100%;
  border-radius: 6px;
  ${theme.breakpoints.up('md')} {
    padding: 16px;
  }
`;

export const WrapperCard = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  height: 57px;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  ${theme.breakpoints.up('md')} {
    height: 78px;
    width: 298px;
    padding: 16px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 258px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 318px;
  }
`;

export const WrapperDot = styled(WrapperBase)<{
  $ischecked: boolean;
  $iserrored: boolean;
  $disablePadding: boolean;
}>`
  margin-bottom: 1em;
  height: 44px;
  width: 100%;
  padding: 8px;
  border-style: solid;
  border-radius: 6px;
  ${theme.breakpoints.up('md')} {
    height: 50px;
    width: 298px;
    padding: 8px 16px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 258px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 318px;
  }
`;

export const RadioInputLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
`;
