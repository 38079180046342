import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateApiError } from '../../redux/slices/helpers';

export default function HandleError(error: unknown, dispatch: Dispatch, unhandledErrorMessage?: string) {
  const errorMessage = unhandledErrorMessage ?? '';
  if (axios.isAxiosError(error)) {
    dispatch(
      updateApiError({
        apiError: {
          hasError: true,
          message:
            error.response?.status === 400 && typeof error.response?.data === 'string'
              ? error.response?.data
              : errorMessage,
        },
      }),
    );
  } else {
    dispatch(
      updateApiError({
        apiError: {
          hasError: true,
          message: '',
        },
      }),
    );
  }
}
