import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors, H4 } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import {
  updateHelperIsProgressShown,
  updateHelperInsurer,
  updateHelperSwitchLapse,
} from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';
import RadioVariants from '../../../enums/radioVariants';
import routes from '../../../enums/routes';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { Main } from '../../templates/styles';
import Header from '../../templates/Header/Header';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { RadioContainer, Wrapper, RadioWrapper, ErrorContainer } from '../triage/styles';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';

type Option = { value: number; title: string };

const OPTIONS: Array<Option> = [
  {
    value: 1,
    title: 'AXA Health or Health-on-Line',
  },
  {
    value: 2,
    title: 'Another health insurance company',
  },
];

export default function Insurer(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const [closeError, setCloseError] = useState(false);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.triage, navigateOptions);
  });

  function Submitter() {
    const { submitForm } = useFormikContext<{ insurer: string }>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  function checkChanges(value: number) {
    if (helpers.insurer !== value) {
      dispatch(updateHelperSwitchLapse({ switchLapse: 0 }));
    }
  }

  return (
    <>
      <Header noPhone />
      <Main>
        <Wrapper>
          <Formik
            initialValues={{ insurer: helpers.insurer }}
            onSubmit={(values) => {
              checkChanges(values.insurer);
              dispatch(updateHelperInsurer({ insurer: values.insurer }));
              switch (values.insurer) {
                case 1:
                case 2:
                  history(routes.switchLapse, helpers.navigateOptions);
                  break;
                default:
                  setValid(false);
                  setCloseError(false);
                  break;
              }
            }}
          >
            <Form>
              <PageIntroduction title="Who was your previous insurer?" titleTestId="title" />
              <RadioContainer>
                {!valid && !closeError &&
                  <ErrorContainer>
                    <ErrorMessageBox closeError={setCloseError} />
                  </ErrorContainer>
                }
                {OPTIONS.map(({ value, title }, index) => (
                  <RadioWrapper
                    key={value}
                    $noGutter={index + 1 === OPTIONS.length}
                    onClick={() => {
                      setValid(true);
                    }}
                  >
                    <RadioBlock
                      isErrored={!valid}
                      value={value}
                      name="insurer"
                      variant={RadioVariants.row}
                      dataTestId={`radioButton-${value}`}
                      checkedColor={colors.oceanBlue}
                    >
                      <H4 color={colors.darkGrey} bold>{title}</H4>
                    </RadioBlock>
                  </RadioWrapper>
                ))}
                <PageNavigation />
              </RadioContainer>
              <Submitter />
            </Form>
          </Formik>
        </Wrapper>
      </Main>
    </>
  );
}
