import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Text, ContactCard, colors } from '@digitalportal-ui/core';
import { useState, useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import { updateApiError, updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import { RetrieveQuoteSchema } from '../../../validationSchema';
import TextInputField from '../../molecules/TextInputField/TextInputField';
import DateFormField from '../../organisms/DateFormField/DateFormField';
import Phone from '../../../assets/svg/phone.svg?react';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import {
  updateQuote,
  updateQuoteSnapshot,
  updateLeadMemberSnapshot,
  MajorHealthQuestions,
  Quote,
  updateQuoteHospitalNetworkOptions,
  Member,
} from '../../../redux/slices/quote';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import {
  PageIntroductionWrapper,
  FormWrapper,
  PostcodeWrapper,
  ButtonWrapper,
  Wrapper,
  ContactCardWrapper,
} from './styles';
import {
  updateCampaign,
  updateHelperEntitlements,
  updateHelperGuidedSelection,
  updateHelperMajorHealthQuestions,
  updateHelperProviders,
  updateModalInfo,
  updateQuoteRetrieved,
  updateSalesPhoneNumber,
} from '../../../redux/slices/helpers/helpers.slice';
import { getQuote } from '../../../lib/utils/services/quoteService';
import { getHospitals, getProductDefinition } from '../../../lib/utils/services/productService';
import Loader from '../../atoms/Loader/Loader';
import { useCreateQuote } from '../../../hooks/useCreateQuote';
import { Campaign, MajorHealthQuestionsAnswersType } from '../../../redux/slices/helpers/helpers.types';
import NetworkCodes from '../../../enums/networks';
import GuidedSelectionOptions from '../../../enums/guidedSelection';
import HandleError from '../../../lib/utils/handleError';
import config from '../../../config/config.json';
import { UnHappyPathVariant } from '../unhappyPath/unhappyPath';

type QuoteValueType = {
  dob: {
    day: string;
    month: string;
    year: string;
  };
  postcode: string;
  quoteKey: string;
};

type GetQuoteResponse = {
  quoteFound: boolean;
  newQuoteNeeded: boolean;
  shouldNavigate: boolean;
};

const IsOfflineRedirectOn = String(config.FEATURES.OFFLINE_REDIRECT) === 'true';
const IsRHLOn = String(config.FEATURES.RHL) === 'true';

export default function RetrieveQuote(): React.JSX.Element {
  const { quoteKey } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [isQuoteNotFound, setIsQuoteNotFound] = useState(false);
  const [isCreatingQuote, setIsCreatingQuote] = useState(false);
  const [showNewQuoteModal, setShowNewQuoteModal] = useState(false);
  const { navigateOptions, availability } = useSelector((state: TStore) => state.helpersState.helpers);
  const initialQuoteValues = useSelector((state: TStore) => state.quoteState.quote);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const dispatch = useDispatch();
  const history = useNavigate();
  const initialValues: QuoteValueType = {
    quoteKey: quoteKey?.toString() ?? '',
    postcode: '',
    dob: {
      day: '',
      month: '',
      year: '',
    },
  };
  const todaysDate = new Date();
  const { requestCreateQuote } = useCreateQuote();

  const unhappyPathOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      description: 'Please call us on the number below',
      variant: UnHappyPathVariant.PhoneCall,
    },
  };

  const modalTitle = 'Before we retrieve your quote';

  useEffect(() => {
    if (IsOfflineRedirectOn) {
      history(routes.unhappyPath, unhappyPathOptions);
    }
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  const updateHelperMHQ = (arr: MajorHealthQuestions[]) => {
    const initHelper: Partial<MajorHealthQuestions> = Object.assign({}, ...arr);

    const answers: MajorHealthQuestionsAnswersType[] = Object.keys(initHelper).map((key: string) => ({
      code: key,
      answerValue: arr.some((member) => member[key] === 'Yes') ? 'Yes' : 'No',
      isFavourable: !arr.some((member) => member[key] === 'Yes'),
    }));
    dispatch(updateHelperMajorHealthQuestions({ answers }));
  };

  const fetchProductDefinition = useCallback(
    async (quoteModel: Quote, coverStartDate: string) => {
      const productDefinition = await getProductDefinition(coverStartDate, quoteModel.members);
      dispatch(updateHelperEntitlements(productDefinition));
      if (!IsRHLOn) {
        const networkOptions = productDefinition.hospitalNetworkConfig.options.filter(
          (x) => x.value !== NetworkCodes.foundationOption && x.value !== NetworkCodes.inSpireOption,
        );
        dispatch(
          updateQuoteHospitalNetworkOptions({
            hospitalNetworkConfig: {
              defaultValue: productDefinition.hospitalNetworkConfig.defaultValue,
              options: networkOptions,
            },
          }),
        );
      } else {
        dispatch(updateQuoteHospitalNetworkOptions({ hospitalNetworkConfig: productDefinition.hospitalNetworkConfig }));
      }
    },
    [dispatch],
  );

  const isDateInPast = (firstDate: Date, secondDate: Date) => {
    if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  };

  const formatNumber = (value: string) => (value.length === 1 ? `0${value}` : value);

  const handleGetQuoteError = (error: AxiosError) => {
    let quoteNotFound = false;
    const statusCode = error?.response?.status;
    const quoteNotFoundStatus = statusCode ? statusCode < 500 : false;

    if (quoteNotFoundStatus) {
      quoteNotFound = true;
    }

    if (!quoteNotFoundStatus) {
      dispatch(
        updateApiError({
          apiError: {
            hasError: true,
            message: error.response?.data as string,
          },
        }),
      );
    }

    return quoteNotFound;
  };

  const handleUpdateQuote = (quoteResponse: Quote, members: Member[], coverStartDate: string) => {
    let guidedSelection = GuidedSelectionOptions.guided;

    dispatch(
      updateQuote({
        ...quoteResponse,
        members,
        coverStartDate,
        monthlyTotalContractPrice: quoteResponse.paymentFrequency === 'Monthly' ? quoteResponse.annualPremium : 0,
        annualTotalContractPrice: quoteResponse.paymentFrequency === 'Annual' ? quoteResponse.annualPremium : 0,
      }),
    );
    dispatch(updateQuoteSnapshot({ ...quoteResponse, members }));
    dispatch(updateLeadMemberSnapshot({ userEditIndex: 0, values: members[0] }));
    dispatch(updateQuoteRetrieved(true));

    if (
      quoteResponse.hospitalNetwork === NetworkCodes.flexibleOption ||
      quoteResponse.hospitalNetwork === NetworkCodes.openOption
    ) {
      guidedSelection = GuidedSelectionOptions.nonGuided;
    }
    dispatch(updateHelperGuidedSelection(guidedSelection));
  };

  const handleOnModalClose = () => {
    setIsCreatingQuote(true);
    setShowNewQuoteModal(!showNewQuoteModal);
    dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
    requestCreateQuote({
      paymentFrequency: 'Monthly',
      triggerQuoteEmail: true,
      updateQuoteDetails: true,
      getFAQs: true,
      successCallback: () => {
        setIsCreatingQuote(false);
        history(routes.quoteSummary, navigateOptions);
      },
      errorCallback: () => setIsCreatingQuote(false),
    });
  };

  const handleOnCallClick = () => makePhoneCall(phoneNumber);

  const GetQuote = async (values: QuoteValueType) => {
    const quoteResponse = {} as GetQuoteResponse;
    try {
      const response = await getQuote({
        PostCode: values.postcode,
        DateOfBirth: `${values.dob.year}-${formatNumber(values.dob.month)}-${formatNumber(values.dob.day)}`,
        QuoteKey: quoteKey?.toString() ?? '',
      });

      quoteResponse.newQuoteNeeded = isDateInPast(new Date(response.coverStartDate), todaysDate);
      const coverStartDate = quoteResponse.newQuoteNeeded
        ? dayjs(todaysDate).format('YYYY-MM-DD')
        : response.coverStartDate;
      const members = [...response.members]
        .sort((member) => (member.policyHolder ? -1 : 1))
        .map((member) => ({
          ...member,
          ncdProtection: member.ncdProtection ? 'Yes' : 'No',
          preferredName: member.preferredName ?? '',
          phone: member.phone ?? '',
        }));

      handleUpdateQuote(response, members, coverStartDate);
      const tagManagerArgs = {
        dataLayer: {
          event: 'retrieved_quote',
          quote_policy_ref: response.quoteNumber,
          quote_total_policy_revenue: response.annualPremium,
        },
      };

      if (response.campaign) {
        const campaignResponse = response.campaign;
        const campaign = {
          marketingRef: campaignResponse.marketingRef,
          marketingSource: campaignResponse.marketingSource,
          termsConditions: campaignResponse.termsConditions,
          incentive: campaignResponse.incentive,
          success: campaignResponse.success,
          loading: false,
        } as Campaign;
        dispatch(updateCampaign({ campaign }));

        if (campaignResponse.phoneNumber && campaignResponse.phoneNumber !== '') {
          dispatch(updateSalesPhoneNumber(campaignResponse.phoneNumber));
        }
      }

      TagManager.dataLayer(tagManagerArgs);
      await fetchProductDefinition(response, coverStartDate);
      updateHelperMHQ(response.members.map((member) => member.majorHealthQuestions));
      quoteResponse.quoteFound = true;
      quoteResponse.shouldNavigate = true;
    } catch (error) {
      quoteResponse.quoteFound = !handleGetQuoteError(error as AxiosError);
    }
    return quoteResponse;
  };

  const getHospitalNetworks = async (postcode: string) => {
    try {
      const response = await getHospitals(postcode);
      dispatch(updateHelperProviders({ providers: response }));
    } catch (err) {
      HandleError(err, dispatch);
    }
  };

  const handleOnFormSubmit = async (values: QuoteValueType, actions: FormikHelpers<QuoteValueType>): Promise<void> => {
    setIsQuoteNotFound(false);
    setIsFetching(true);

    const quoteResponse = await GetQuote(values);

    const { quoteFound, newQuoteNeeded, shouldNavigate } = quoteResponse;
    setIsQuoteNotFound(!quoteFound);

    if (shouldNavigate) {
      await getHospitalNetworks(values.postcode);
    }

    actions.setSubmitting(false);
    setIsFetching(false);

    const isNewQuoteNeeded = newQuoteNeeded && initialQuoteValues?.quoteStatus !== 'Expired';
    const shouldNavigateToQuoteSummary = quoteFound && !isNewQuoteNeeded && shouldNavigate;
    const shouldDisplayNewQuoteModal = quoteFound && isNewQuoteNeeded;

    if (shouldNavigateToQuoteSummary) {
      history(routes.quoteSummary, navigateOptions);
    }

    if (shouldDisplayNewQuoteModal) {
      setShowNewQuoteModal(true);
      dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
    }
  };

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Wrapper display="flex" flexDirection="column" alignItems="center" $isCreatingQuote={isCreatingQuote}>
          {isCreatingQuote ? (
            <Loader
              title="Retrieving your quote"
              subtitle="Whatever healthy looks like for you, we’re here to help you get there."
            />
          ) : (
            <>
              <PageIntroductionWrapper>
                <PageIntroduction
                  title="Get back to your quote"
                  subtitle="We just need a few details to get you back in"
                  titleTestId="title"
                  subtitleTestId="subtitle"
                />
              </PageIntroductionWrapper>
              <Formik
                validateOnBlur
                initialValues={initialValues}
                validateOnMount
                enableReinitialize
                onSubmit={handleOnFormSubmit}
                validationSchema={RetrieveQuoteSchema}
              >
                <Form>
                  <FormWrapper>
                    <PostcodeWrapper>
                      <TextInputField
                        placeholder="Enter your postcode"
                        label="Postcode"
                        name="postcode"
                        testId="retrieve-quote-postcode"
                        wrapperMarginDisabled
                      />
                    </PostcodeWrapper>
                    <DateFormField inputFieldName="dob" testId="retrieve-quote-dob" />
                    <ButtonWrapper>
                      <Button loading={isFetching} disabled={isFetching} fullWidth data-testid="retrieve-quote-button">
                        View my quote
                      </Button>
                    </ButtonWrapper>
                  </FormWrapper>
                </Form>
              </Formik>
            </>
          )}
          {isQuoteNotFound ? (
            <ContactCardWrapper>
              <ContactCard
                icon={<Phone />}
                header="Sorry, we can’t find or recognise your details"
                subHeader="Please check the information you entered and try again."
                buttonText={`Call ${phoneNumber}`}
                avatarBgColor={availability.availabilityStatus ? colors.pacificBlue10 : colors.mercury}
                statusTextColor={availability.workTimeColor}
                availabilityStatus={availability.workTimeText}
                title="Call us"
                isButtonDisabled={!availability.availabilityStatus}
                onButtonClick={handleOnCallClick}
                testId="RetrievalContactcard"
                buttonWidthFitContent
              />
            </ContactCardWrapper>
          ) : null}
        </Wrapper>
        <Modal
          variant="info"
          title="Before we retrieve your quote"
          open={showNewQuoteModal}
          onClose={handleOnModalClose}
          dataTestid="retrieve-quote-modal"
        >
          <Box>
            <Text>
              You&apos;ve gone past the start date you chose, so we&apos;ve updated your start date to today. You can
              change this on the next screen if you need to.
            </Text>
          </Box>
        </Modal>
      </Main>
    </>
  );
}
