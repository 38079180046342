import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors, H4, Text } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import { updateHelperIsProgressShown, updateHelperSwitchLapse } from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';
import RadioVariants from '../../../enums/radioVariants';
import routes from '../../../enums/routes';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { Main } from '../../templates/styles';
import Header from '../../templates/Header/Header';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { RadioContainer, Wrapper, RadioWrapper, ErrorContainer } from '../triage/styles';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';

type Option = { value: number; title: string; subtitle: string };

export default function SwitchLapse(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const [closeError, setCloseError] = useState(false);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const insurerOption = useSelector((state: TStore) => state.helpersState.helpers.insurer);
  const triageOption = useSelector((state: TStore) => state.helpersState.helpers.triage);

  const OPTIONS4: Array<Option> = [
    {
      value: 1,
      title: 'Less than 4 months ago',
      subtitle: insurerOption === 1 ? 'Your policy ended on or after ' : 'Your health insurance ended on or after ',
    },
    {
      value: 2,
      title: 'More than 4 months ago',
      subtitle: insurerOption === 1 ? 'Your policy ended on or before ' : 'Your health insurance ended on or before ',
    },
  ];

  const OPTIONS3: Array<Option> = [
    {
      value: 1,
      title: 'Less than 3 months ago',
      subtitle: insurerOption === 1 ? 'Your policy ended on or after ' : 'Your health insurance ended on or after ',
    },
    {
      value: 2,
      title: 'More than 3 months ago',
      subtitle: insurerOption === 1 ? 'Your policy ended on or before ' : 'Your health insurance ended on or before ',
    },
  ];

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.insurer, navigateOptions);
  });

  function Submitter() {
    const { submitForm } = useFormikContext<{ switchLapse: string }>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  const getDate = (switchLapseOptionvalue: number) => {
    if (switchLapseOptionvalue === 1) {
      return dayjs()
        .subtract(
          (triageOption === 2 && insurerOption === 1) ||
            (triageOption === 3 && insurerOption === 1)
            ? 119
            : 89,
          'day',
        )
        .format('Do MMM YYYY');
    }
    return dayjs()
      .subtract(
        (triageOption === 2 && insurerOption === 1) ||
          (triageOption === 3 && insurerOption === 1)
          ? 120
          : 90,
        'day',
      )
      .format('Do MMM YYYY');
  };

  return (
    <>
      <Header noPhone />
      <Main>
        <Wrapper>
          <Formik
            initialValues={{ switchLapse: helpers.switchLapse }}
            onSubmit={(values) => {
              dispatch(updateHelperSwitchLapse({ switchLapse: values.switchLapse }));
              switch (values.switchLapse) {
                case 1:
                  history(routes.switchHandoff, helpers.navigateOptions);
                  break;
                case 2:
                  history(routes.beforeYouStart, helpers.navigateOptions);
                  break;
                default:
                  setValid(false);
                  setCloseError(false);
                  break;
              }
            }}
          >
            <Form>
              {insurerOption === 1 ? (
                <PageIntroduction title="When did your policy with us expire?" titleTestId="title" />
              ) : (
                <PageIntroduction title="When did your health insurance end?" titleTestId="title" />
              )}
              <RadioContainer>
                {!valid && !closeError && (
                  <ErrorContainer>
                    <ErrorMessageBox closeError={setCloseError} />
                  </ErrorContainer>
                )}
                {((triageOption === 2 && insurerOption === 1) ||
                (triageOption === 3 && insurerOption === 1)
                  ? OPTIONS4
                  : OPTIONS3
                ).map(({ value, title, subtitle }, index) => (
                  <RadioWrapper
                    key={value}
                    $noGutter={index + 1 === OPTIONS3.length || index + 1 === OPTIONS4.length}
                    onClick={() => setValid(true)}
                  >
                    <RadioBlock
                      isErrored={!valid}
                      value={value}
                      name="switchLapse"
                      variant={RadioVariants.row}
                      checkedColor={colors.oceanBlue}
                      dataTestId={`radioButton-${value}`}

                    >
                      <H4 color={colors.darkGrey} bold>
                        {title}
                      </H4>
                      <Text color={colors.darkGrey}>
                        {subtitle}
                        <Box component="span" fontWeight={600}>
                          {getDate(value)}
                        </Box>
                      </Text>
                    </RadioBlock>
                  </RadioWrapper>
                ))}
                <PageNavigation />
              </RadioContainer>
              <Submitter />
            </Form>
          </Formik>
        </Wrapper>
      </Main>
    </>
  );
}
