import { Box } from '@mui/material';
import { Text, H2, colors } from '@digitalportal-ui/core';
import { useSelector } from 'react-redux';
import DownloadCard from '../../../molecules/DownloadCard/DownloadCard';
import { Wrapper, DocumentWrapper } from './styles';
import MembershipHandbook from '../../../../assets/pdfs/AXA Health Plan Handbook.pdf';
import MembershipHandbookRHL from '../../../../assets/pdfs/AXA Health Plan generic handbook with all hospital list options.pdf';
import AboutAXAHealthAHP from '../../../../assets/pdfs/About AXA Health AHP.pdf';
import { TStore } from '../../../../redux/store';
import { isCoverStartDateBeforeCutOffDate } from '../../../../lib/utils/isCoverStartDateBeforeCutOffDate';
import NetworkCodes from '../../../../enums/networks';
import config from '../../../../config/config.json';

const IsRHLOn = String(config.FEATURES.RHL) === 'true';

export const inSpireHospitalListPdf =
  'https://www.axahealth.co.uk/globalassets/axa-health-plan/pdfs/inspire-ahp-directory-of-hospitals.pdf';
export const foundationHospitalListPdf =
  'https://www.axahealth.co.uk/globalassets/axa-health-plan/pdfs/ahp-foundation-directory-of-hospitals.pdf';
export const guidedHospitalListPdf =
  'https://www.axahealth.co.uk/globalassets/axa-health-plan/pdfs/ahp-guided-directory-of-hospitals.pdf';

export default function DownloadCards(): React.JSX.Element {
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const { coverStartDate, hospitalNetwork } = useSelector((state: TStore) => state.quoteState.quote);

  const getHospitalNetworkPdf = (network: string): string => {
    if (network === NetworkCodes.inSpireOption) {
      return inSpireHospitalListPdf;
    }
    if (network === NetworkCodes.foundationOption) {
      return foundationHospitalListPdf;
    }

    return guidedHospitalListPdf;
  };

  const pdfDownloads = [
    {
      title: 'Membership handbook',
      subtitle: 'Tells you what you are and aren’t covered for',
      link: !isCoverStartDateBeforeCutOffDate(coverStartDate) && IsRHLOn ? MembershipHandbookRHL : MembershipHandbook,
    },
    {
      title: 'Information about AXA Health',
      subtitle: 'Legal information about who we are',
      link: AboutAXAHealthAHP,
    },
    {
      title: 'Directory of hospitals',
      subtitle: 'Shows all the hospitals on your list',
      link: getHospitalNetworkPdf(hospitalNetwork),
    },
  ];

  const availablePdfDownloads = IsRHLOn ? pdfDownloads : pdfDownloads.slice(0, -1);

  return (
    <Wrapper>
      <H2
        align="left"
        width="100%"
        sx={{ marginBottom: { sm: '16px', md: '16px' } }}
        data-testid="title-useful-documents"
      >
        Information to check
      </H2>
      <Text
        align="left"
        paddingTop="0px"
        fontWeight="600"
        line-height="20.11px"
        sx={{ marginBottom: { sm: '16px', md: '32px' } }}
      >
        Please read these documents carefully before you buy.
        <br />
        If you go ahead, we&apos;ll send you another handbook that&apos;s personalised to the cover you&apos;ve chosen.
      </Text>
      <DocumentWrapper>
        {availablePdfDownloads.map(({ title, subtitle, link }) => (
          <DownloadCard
            key={title}
            title={title}
            subtitle={subtitle}
            link={link}
            urlText="Download"
            fileSizeText="PDF 200 MB"
          />
        ))}
      </DocumentWrapper>
      <Text align="left" padding="0px" data-testid="description-useful-documents">
        Want a paper copy? Please call us on:{' '}
        <Box component="span" fontWeight="600" color={colors.oceanBlue300}>
          {phoneNumber}
        </Box>
        . We&apos;re here Monday to Friday 9am-6pm.
      </Text>
    </Wrapper>
  );
}
