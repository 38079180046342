import RadioVariants from '../../../enums/radioVariants';

export default {
  ukbankRadio: {
    label:
      'Do you want to pay from a UK bank or building society account that’s in your own name? (so you’re the account holder.)',
    values: [
      {
        label: 'Yes',
        name: 'ukbank',
        value: 1,
        type: RadioVariants.dot,
      },
      {
        label: 'No',
        name: 'ukbank',
        value: 0,
        type: RadioVariants.dot,
      },
    ],
  },
  singlePerson: {
    label: 'Can you authorise payments from this account by yourself?',
    values: [
      {
        label: 'Yes',
        name: 'singleperson',
        value: 1,
        type: RadioVariants.dot,
      },
      {
        label: 'No',
        name: 'singleperson',
        value: 0,
        type: RadioVariants.dot,
      },
    ],
  },
};
