import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { IframeWrapper } from './styles';
import { CardDetailsFormProps } from './types';
import configData from '../../../config/config.json';
import { TStore } from '../../../redux/store';
import Routes from '../../../enums/routes';
import { appInsights } from '../../../lib/utils/services/applicationInsightsService';

export default function CardDetailsForm({ onSubmit, checkoutMessage }: CardDetailsFormProps): React.JSX.Element {
  const IFrameRef = useRef<HTMLIFrameElement>(null);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const { quoteNumber } = useSelector((state: TStore) => state.quoteState.quote);
  const history = useNavigate();

  const paymentErrorOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We’re having some problems with our online payment system right now',
      body: `Please try again or call us and use your quote number: ${quoteNumber}`,
      route: Routes.paymentDetails,
      fromRoute: Routes.cardDetails,
    },
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== configData.CHECKOUT_HOST) {
        return;
      }

      const data = JSON.parse(event.data);

      if (data.type === 'secureHostedCollection' && data.state === 'ready' && IFrameRef) {
        IFrameRef.current?.contentWindow?.postMessage(JSON.stringify(checkoutMessage), configData.CHECKOUT_HOST);
      }

      if (data.type === 'storeResult' && data.state === 'complete' && IFrameRef?.current) {
        IFrameRef.current.src = configData.VERIFY_TRANSACTION_URL;
      }

      if (data.type === 'verifyTransaction') {
        switch (data.state) {
          case 'ready':
            IFrameRef?.current?.contentWindow?.postMessage(JSON.stringify(checkoutMessage), configData.CHECKOUT_HOST);
            break;
          case 'VERIFIED':
            onSubmit({
              transactionReference: checkoutMessage.transactionReference,
              policyNumber: checkoutMessage.salesDetails,
            });
            break;
          case 'CANCELLED':
            history(Routes.paymentDetails, navigateOptions);
            break;
          default:
            history(Routes.unhappyPath, paymentErrorOptions);
            appInsights.trackException({
              exception: new Error(
                `Credit card MessageEvent data.state is '${data.state}'. Unable to verify transaction.`,
              ),
            });
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return <IframeWrapper ref={IFrameRef} src={configData.CHECKOUT_URL} title="cardCollection" frameBorder={0} />;
}
