import { H3, Text, colors } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import RadioVariants from '../../../../enums/radioVariants';
import RadioBlock from '../../../molecules/RadioBlock/RadioBlock';
import { ContentSection, RadioContainer } from '../styles';
import { HowWouldYouLikeToPayObject, PaymentFrequencyQuestionProps } from '../types';
import ErrorMessageBox from '../../../molecules/ErrorMessageBox/errorMessageBox';
import QuestionHeader from '../../../atoms/QuestionHeader/QuestionHeader';

export const payOptions: HowWouldYouLikeToPayObject[] = [
  {
    text: 'Annually (Save 5%)',
    value: 'Annual',
  },
  {
    text: 'Monthly',
    value: 'Monthly',
  },
];

function PaymentFrequencyQuestion({
  touched,
  value,
  errorClose,
  setErrorClose,
  annualPremium,
  monthlyPremium,
  onPaymentFrequencyChange,
}: PaymentFrequencyQuestionProps): React.JSX.Element {
  return (
    <ContentSection>
      <QuestionHeader title="How often do you want to pay?" testId="payment-frequency-question" />
      <RadioContainer sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        {payOptions.map((element: { text: string }, index: number) => (
          <Box key={`box-${element.text}`} sx={{ marginBottom: { xs: '16px' } }}>
            <RadioBlock
              variant={RadioVariants.card}
              key={element.text}
              value={index}
              name="paymentFrequency"
              checkedColor={colors.oceanBlue}
              isErrored={touched && value === undefined}
              dataTestId={`radioButton-${element.text.replace(/\s+/g, '-')}`}
              onClick={() => onPaymentFrequencyChange(index === 1)}
            >
              <Box>
                <H3 bold>{element.text}</H3>
                <Text variant="body1">
                  {element.text === 'Annually (Save 5%)' ? 'Total Cost: ' : 'Monthly Amount: '}&#163;
                  {element.text === 'Annually (Save 5%)'
                    ? annualPremium?.toLocaleString('en-UK', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : monthlyPremium?.toLocaleString('en-UK', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Text>
              </Box>
            </RadioBlock>
          </Box>
        ))}
      </RadioContainer>
      {touched && value === undefined && !errorClose && (
        <Box sx={{ marginBottom: { xs: '16px' } }}>
          <ErrorMessageBox closeError={setErrorClose} />
        </Box>
      )}
    </ContentSection>
  );
}

export default PaymentFrequencyQuestion;
