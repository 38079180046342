import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors, H4, Text } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import {
  resetHelpers,
  updateHelperInsurer,
  updateHelperIsProgressShown,
  updateHelperSwitchLapse,
  updateHelperTriage,
} from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';
import RadioVariants from '../../../enums/radioVariants';
import routes from '../../../enums/routes';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { Main } from '../../templates/styles';
import Header from '../../templates/Header/Header';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { RadioContainer, Wrapper, RadioWrapper, ErrorContainer, BusinessCardWrapper } from './styles';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import { resetQuote } from '../../../redux/slices/quote';
import { resetPolicy } from '../../../redux/slices/policy/policy.slice';
import BusinessCard from '../../molecules/BusinessCard/BusinessCard';

type Option = { value: number; title: string; subtitle?: string };

const OPTIONS: Array<Option> = [
  {
    value: 1,
    title: 'No, I’ve not had health insurance in the last 4 months',
    subtitle: 'This includes health insurance that you took out yourself, or that you had through a company',
  },
  {
    value: 2,
    title: 'Yes, a policy I took out myself',
  },
  {
    value: 3,
    title: 'Yes, a policy that my company set up for me',
  },
];

export default function Triage(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const [closeError, setCloseError] = useState(false);
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const policyPayed = useSelector((state: TStore) => state.policyState.policy.policyPayed);

  useEffect(() => {
    if (policyPayed) {
      dispatch(resetQuote());
      dispatch(resetPolicy());
      dispatch(resetHelpers());
    }

    dispatch(updateHelperIsProgressShown({ isProgressShown: false }));
  }, [dispatch]);

  function Submitter() {
    const { submitForm } = useFormikContext<{ triage: string }>();
    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  function checkChanges(value: number) {
    if (helpers.triage !== value) {
      dispatch(updateHelperInsurer({ insurer: 0 }));
      dispatch(updateHelperSwitchLapse({ switchLapse: 0 }));
    }
  }

  return (
    <>
      <Header />
      <Main>
        <Wrapper>
          <Formik
            initialValues={{ triage: helpers.triage }}
            onSubmit={(values) => {
              const selectedOption = OPTIONS.find((option) => option.value === values.triage);
              if (selectedOption) {
                const tagManagerArgs = {
                  dataLayer: {
                    event: 'your_history_answers',
                    selection_type: 'radio button',
                    selection_title: selectedOption.title,
                  },
                };
                TagManager.dataLayer(tagManagerArgs);
              }
              checkChanges(values.triage);
              dispatch(updateHelperTriage({ triage: values.triage }));
              switch (values.triage) {
                case 1:
                  history(routes.beforeYouStart, helpers.navigateOptions);
                  break;
                case 2:
                case 3:
                  history(routes.insurer, helpers.navigateOptions);
                  break;
                default:
                  setValid(false);
                  setCloseError(false);
                  break;
              }
            }}
          >
            <Form>
              <PageIntroduction title="Have you had health insurance in the last 4 months?" titleTestId="title" />
              <RadioContainer>
                {!valid && !closeError && (
                  <ErrorContainer>
                    <ErrorMessageBox closeError={setCloseError} />
                  </ErrorContainer>
                )}
                {OPTIONS.map(({ value, title, subtitle }, index) => (
                  <RadioWrapper $noGutter={index + 1 === OPTIONS.length} key={value} onClick={() => setValid(true)}>
                    <RadioBlock
                      isErrored={!valid}
                      value={value}
                      name="triage"
                      dataTestId={`radioButton-${value}`}
                      variant={RadioVariants.row}
                      checkedColor={colors.oceanBlue}
                    >
                      <H4 color={colors.darkGrey} bold>
                        {title}
                      </H4>
                      {subtitle && <Text color={colors.darkGrey}>{subtitle}</Text>}
                    </RadioBlock>
                  </RadioWrapper>
                ))}
                <BusinessCardWrapper>
                  <BusinessCard />
                </BusinessCardWrapper>
                <PageNavigation />
              </RadioContainer>
              <Submitter />
            </Form>
          </Formik>
        </Wrapper>
      </Main>
    </>
  );
}
