import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import Triage from './components/pages/triage/triage';
import Insurer from './components/pages/insurer/insurer';
import SwitchLapse from './components/pages/switchLapse/switchLapse';
import SwitchHandoff from './components/pages/switchHandoff/switchHandoff';
import BeforeYouStart from './components/pages/beforeYouStart/beforeYouStart';
import CoverStartDate from './components/pages/coverStartDate/coverStartDate';
import YourName from './components/pages/yourName/yourName';
import DateOfBirth from './components/pages/dateOfBirth/dateOfBirth';
import Address from './components/pages/address/address';
import ContactDetails from './components/pages/contactDetails/contactDetails';
import Entitlements from './components/pages/entitlements/entitlements';
import GuidedSelection from './components/pages/guidedSelection/guidedSelection';
import Excess from './components/pages/excess/excess';
import Consultations from './components/pages/consultations/consultations';
import Hospitals from './components/pages/hospitals/hospitals';
import QuoteSummary from './components/pages/quoteSummary/quoteSummary';
import PaymentDetails from './components/pages/paymentDetails/paymentDetails';
import CheckoutPolicyDocs from './components/pages/checkoutPolicyDocs/checkoutPolicyDocs';
import CardDetails from './components/pages/cardDetails';
import DirectDebit from './components/pages/DirectDebit/directDebit';
import DirectDebitInstructions from './components/pages/directDebitInstructions/directDebitInstructions';
import Error404 from './components/pages/error404';
import RetrieveQuote from './components/pages/retrieveQuote/retrieveQuote';
import AdditionalMembers from './components/pages/additionalMembers/additionalMembers';
import MajorHealthQuestions from './components/pages/majorHealthQuestions/majorHealthQuestions';
import PaymentSuccess from './components/pages/paymentSuccess/paymentSuccess';
import routes from './enums/routes';
import { Root } from './components/templates/styles';
import { modalTabIndexFix } from './lib/utils/modalTabIndexFix';
import { TStore } from './redux/store';
import Ncd from './components/pages/ncd/ncd';
import configData from './config/config.json';
import OfflineHandler from './lib/offlineHandler/offlineHandler';
import UnhappyPath from './components/pages/unhappyPath/unhappyPath';
import { CampaignRequest, getCampaign } from './lib/utils/services/productService';
import { updateCampaign, updateSalesPhoneNumber } from './redux/slices/helpers';
import { Campaign } from './redux/slices/helpers/helpers.types';
import { emitCustomEvent } from './lib/utils/eventHandler';
import OfflineRedirect from './components/pages/offlineRedirect/offlineRedirect';

export default function App(): React.JSX.Element {
  const [searchParams] = useSearchParams();
  const [cookies, setCookies, removeCookie] = useCookies(['campaign_name', 'source_ref']);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname } = useLocation();
  const locationState = useLocation().state as { usedRouter: boolean };
  const modalInfo = useSelector((state: TStore) => state.helpersState.helpers.modal);
  const campaignInfo = useSelector((state: TStore) => state.helpersState.helpers.campaign);
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

  const handleBackNavigation = () => emitCustomEvent('pageNavigationBack');

  const updateCampaignInfo = (marketingRef: string, marketingSource: string) => {
    dispatch(updateCampaign({ campaign: { loading: true } }));
    const campaignRequest = {
      marketingRef,
      marketingSource,
    } as CampaignRequest;

    getCampaign(campaignRequest)
      .then((response) => {
        if (response.success) {
          const campaign = {
            marketingRef,
            marketingSource,
            termsConditions: response.termsConditions,
            incentive: response.incentive,
            success: response.success,
            loading: false,
          } as Campaign;
          dispatch(updateCampaign({ campaign }));
          if (response.phoneNumber && response.phoneNumber !== '') {
            dispatch(updateSalesPhoneNumber(response.phoneNumber));
          }
        } else {
          removeCookie('campaign_name');
          removeCookie('source_ref');
          dispatch(
            updateCampaign({
              campaign: {
                success: false,
                termsConditions: '',
                incentive: '',
                loading: false,
              },
            }),
          );
        }
      })
      .catch(() => {
        removeCookie('campaign_name');
        removeCookie('source_ref');
        dispatch(updateCampaign({ campaign: { loading: false } }));
      });
  };

  useEffect(() => {
    // Preventing Iphones from auto zooming on fields
    if (isIOS) {
        document
          ?.querySelector('[name=viewport]')
          ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    }

    // Intercepting the browser back button and making it use our router navigation handler
    window.addEventListener('popstate', handleBackNavigation);
    return () => window.removeEventListener('popstate', handleBackNavigation);
  });

  useEffect(() => {
    const campaignParam = searchParams.get('utm_campaign');
    const sourceRefParam = searchParams.get('utm_source');
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    if (campaignParam && sourceRefParam && campaignParam !== cookies.campaign_name) {
      setCookies('campaign_name', campaignParam, { expires: expiryDate });
      setCookies('source_ref', sourceRefParam, { expires: expiryDate });
      updateCampaignInfo(campaignParam, sourceRefParam);
    }

    if (cookies.campaign_name && cookies.source_ref && !campaignInfo.success) {
      updateCampaignInfo(cookies.campaign_name, cookies.source_ref);
    }
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: configData.GTM_KEY,
    };
    TagManager.initialize(tagManagerArgs);
    const tagManagerPageArgs = {
      dataLayer: {
        environment: String(configData.ENV) === 'pr' ? 'prod' : 'test',
        website: 'ppp',
        op_co: 'PPP',
        page_type: 'quote',
        page_product: 'AXA Health Plan',
        page_name: 'Your insurance history',
      },
    };
    TagManager.dataLayer(tagManagerPageArgs);
  }, []);

  useEffect(() => {
    if (modalInfo.isModalOpen) {
      modalTabIndexFix(-1);
      if (modalInfo.modalTitle !== '') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'modal_view',
            modal_title: modalInfo.modalTitle,
          },
        });
      }
    } else {
      modalTabIndexFix(0);
    }
  }, [modalInfo]);

  useEffect(() => {
    const retrieveIndex = routes.retrieveQuote.lastIndexOf('/');
    if (
      (locationState === null || !locationState.usedRouter) &&
      !pathname.includes(routes.retrieveQuote.slice(0, retrieveIndex))
    ) {
      history(routes.triage);
    }
    window.scrollTo(0, 0);
  }, [pathname, locationState, history]);

  return (
    <OfflineHandler>
      <Root>
        <Routes>
          <Route path={routes.triage} element={<Triage />} />
          <Route path={routes.insurer} element={<Insurer />} />
          <Route path={routes.switchLapse} element={<SwitchLapse />} />
          <Route path={routes.switchHandoff} element={<SwitchHandoff />} />
          <Route path={routes.beforeYouStart} element={<BeforeYouStart />} />
          <Route path={routes.coverStartDate} element={<CoverStartDate />} />
          <Route path={routes.yourName} element={<YourName />} />
          <Route path={routes.dateOfBirth} element={<DateOfBirth />} />
          <Route path={routes.address} element={<Address />} />
          <Route path={routes.contactDetails} element={<ContactDetails />} />
          <Route path={routes.additionalMembers} element={<AdditionalMembers />} />
          <Route path={routes.offlineRedirect} element={<OfflineRedirect />} />
          <Route path={routes.majorHealthQuestions} element={<MajorHealthQuestions />} />
          <Route path={routes.entitlements} element={<Entitlements />} />
          <Route path={routes.excess} element={<Excess />} />
          <Route path={routes.guidedSelection} element={<GuidedSelection />} />
          <Route path={routes.consultations} element={<Consultations />} />
          <Route path={routes.hospitals} element={<Hospitals />} />
          <Route path={routes.ncd} element={<Ncd />} />
          <Route path={routes.quoteSummary} element={<QuoteSummary />} />
          <Route path={routes.checkoutPolicyDocs} element={<CheckoutPolicyDocs />} />
          <Route path={routes.paymentDetails} element={<PaymentDetails />} />
          <Route path={routes.cardDetails} element={<CardDetails />} />
          <Route path={routes.directDebit} element={<DirectDebit />} />
          <Route path={routes.directDebitInstruction} element={<DirectDebitInstructions />} />
          <Route path={routes.unhappyPath} element={<UnhappyPath />} />
          <Route path={routes.paymentSuccess} element={<PaymentSuccess />} />
          <Route path={routes.retrieveQuote} element={<RetrieveQuote />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Root>
    </OfflineHandler>
  );
}
