import { useField } from 'formik';
import { Box, Grid, GridDirection, GridSize, Radio } from '@mui/material';
import { colors } from '@digitalportal-ui/core';

import {
  WrapperBase,
  WrapperRow,
  WrapperBrick,
  WrapperColumnWithAdvert,
  WrapperRowWide,
  WrapperCard,
  WrapperDot,
  RadioInputLabel,
} from './styles';
import { RadioBlockProps, RadioCircleProps } from './types';
import RadioVariants from '../../../enums/radioVariants';

function RadioCircle({ isErrored, isChecked, checkedColor, name, tabIndex }: RadioCircleProps): React.JSX.Element {
  return (
    <Grid item style={{ marginTop: '-2px', maxWidth: '30px' }}>
      <Box color="red">
        <Radio
          checked={isChecked && !isErrored}
          style={{ padding: '0 15px 0 0', color: (isChecked && checkedColor) || colors.grey }}
          name={name}
          tabIndex={tabIndex}
          inputProps={{ 'aria-label': name }}
        />
      </Box>
    </Grid>
  );
}

export default function RadioBlock({
  children,
  name,
  value,
  variant,
  isEnabled = true,
  checkedColor,
  isErrored = false,
  dataTestId,
  disablePadding = false,
  onClick,
  tabIndex = 0,
}: RadioBlockProps): React.JSX.Element {
  const [field, , helpers] = useField(name);
  const isChecked = field.value === value;
  let Wrapper;
  let Size: GridSize | undefined;
  let SizeMd: GridSize | undefined;
  let ContentSize: GridSize | undefined;
  let directionVariant: GridDirection | undefined;
  let alignedVariant: string | undefined;
  let contentVariant: string | undefined;

  switch (variant) {
    case RadioVariants.row:
      Wrapper = WrapperRow;
      ContentSize = 12;
      Size = 10;
      SizeMd = 11;
      break;
    case RadioVariants.brick:
      Wrapper = WrapperBrick;
      break;
    case RadioVariants.rowWide:
      ContentSize = 12;
      Size = 10;
      SizeMd = 11;
      Wrapper = WrapperRowWide;
      alignedVariant = 'flex-start';
      break;
    case RadioVariants.card:
      Wrapper = WrapperCard;
      contentVariant = 'left';
      alignedVariant = 'flex-start';
      break;
    case RadioVariants.dot:
      ContentSize = 12;
      Size = 10;
      Wrapper = WrapperDot;
      alignedVariant = 'center';
      break;
    case RadioVariants.columnWithAdvert:
      Wrapper = WrapperColumnWithAdvert;
      directionVariant = 'column';
      break;
    default:
      Wrapper = WrapperBase;
      alignedVariant = 'center';
  }

  return (
    <Wrapper
      $ischecked={isChecked}
      $iserrored={isErrored}
      $disablePadding={disablePadding}
      onClick={() => {
        if (isEnabled) {
          helpers.setValue(value);
          if (onClick) {
            onClick();
          }
        }
      }}
      elevation={+(variant !== RadioVariants.dot)}
      data-testid={dataTestId}
    >
      {variant === RadioVariants.brick ? (
        <RadioInputLabel>
          <Radio
            checked={isChecked}
            style={{ color: isChecked ? colors.oceanBlue : colors.grey }}
            tabIndex={tabIndex}
            inputProps={{ 'aria-label': `${name} - ${value}` }}
          />
          {children}
        </RadioInputLabel>
      ) : (
        <Grid
          xs={ContentSize}
          item
          direction={directionVariant}
          alignItems={alignedVariant}
          justifyContent={contentVariant}
          container
        >
          <RadioCircle
            variant={variant}
            isErrored={isErrored}
            isChecked={isChecked}
            checkedColor={checkedColor}
            name={name}
            tabIndex={tabIndex}
          />
          <Grid xs={Size} md={SizeMd} item>
            {children}
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
}
