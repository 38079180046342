import { useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CardDetailsForm from '../organisms/CardDetailsForm/CardDetailsForm';
import ConfirmAndPaySummary from '../organisms/ConfirmAndPaySummary/ConfirmAndPaySummary';
import { TStore } from '../../redux/store';
import Routes from '../../enums/routes';
import Header from '../templates/Header/Header';
import { Main } from '../templates/styles';
import {
  updatePaymentMethodDetails,
  updatePaymentMethodType,
  updatePolicyNumber,
  updatePolicyPayed,
} from '../../redux/slices/policy/policy.slice';
import { useCustomEventListener } from '../../lib/utils/eventHandler';

import { CheckoutMessage, SubmitProps } from '../organisms/CardDetailsForm/types';
import PageIntroduction from '../molecules/PageIntroduction/PageIntroduction';
import { MainWrapper, MainContentWrapper, SummaryWrapper } from './paymentDetails/styles';
import Loader from '../atoms/Loader/Loader';
import { BeginPayment } from '../../redux/slices/policy/policy.types';
import { beginPayment, quoteToPolicy } from '../../lib/utils/services/policyService';

export default function CardDetails(): React.JSX.Element {
  const initialValues = useSelector((state: TStore) => state.quoteState.quote);
  const policyState = useSelector((state: TStore) => state.policyState.policy);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const paymentErrorOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We’re having problems setting up your policy',
      body: `Please try again or call us and use your quote number: ${initialValues.quoteNumber}`,
      route: Routes.paymentDetails,
      fromRoute: Routes.cardDetails,
    },
  };

  const checkoutInitialValues: CheckoutMessage = {
    transactionNo: policyState.quoteNumber,
    salesDetails: '',
    transactionReference: '',
  };

  const [checkoutMessage, setCheckoutMessage] = useState(checkoutInitialValues);

  useCustomEventListener('pageNavigationBack', () => {
    history(Routes.paymentDetails, navigateOptions);
  });
  const [navigateToNextPage, setNavigateToNextPage] = useState(false);

  const onSubmit = (values: SubmitProps) => {
    dispatch(updatePaymentMethodType('Card'));
    dispatch(
      updatePaymentMethodDetails({
        directDebit: null,
        card: {
          transactionReference: values.transactionReference,
        },
      }),
    );
    dispatch(updatePolicyNumber(values.policyNumber));
    setNavigateToNextPage(true);
  };

  useEffect(() => {
    if (policyState.policyPayed) {
      history(Routes.triage, navigateOptions);
    } else {
      setLoading(true);
      const beginRequest: BeginPayment = {
        quoteNumber: policyState.quoteNumber,
        quoteKey: policyState.quoteKey,
        paymentFrequency: policyState.paymentFrequency,
      };

      beginPayment(beginRequest)
        .then((response) => {
          checkoutInitialValues.transactionReference = response.transactionReference;
          checkoutInitialValues.salesDetails = response.salesDetails;
          setCheckoutMessage(checkoutInitialValues);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          history(Routes.unhappyPath, {
            state: {
              ...paymentErrorOptions.state,
              title: 'We’re having some problems with our online payment system right now',
            },
          });
        });
    }
  }, []);

  useEffect(() => {
    if (navigateToNextPage) {
      setTimeout(async () => {
        try {
          if (!policyState.policyPayed) {
            await quoteToPolicy(policyState).then((resp) => {
              dispatch(updatePolicyNumber(resp.policyNumber));
            });
            dispatch(updatePolicyPayed(true));
          }
          history(Routes.paymentSuccess, navigateOptions);
        } catch (error) {
          history(Routes.unhappyPath, paymentErrorOptions);
        }
      }, 100);
    }
  }, [navigateToNextPage]);

  if (loading) {
    return (
      <>
        <Header />
        <Main maxWidth="xl">
          <Loader title="Just a moment." subtitle="We are just getting things ready." />
        </Main>
      </>
    );
  }

  return (
    <>
      <Header />
      <Main maxWidth="xl">
        <PageIntroduction title="Secure payment" titleTestId="title" />
        <MainWrapper>
          <MainContentWrapper>
            <CardDetailsForm onSubmit={onSubmit} checkoutMessage={checkoutMessage} />
          </MainContentWrapper>
          <SummaryWrapper>
            <ConfirmAndPaySummary
              cardData={initialValues}
              isMonthlyShown={policyState.paymentFrequency === 'Monthly'}
              regularPaymentDate={policyState.regularPaymentDay}
            />
          </SummaryWrapper>
        </MainWrapper>
      </Main>
    </>
  );
}
