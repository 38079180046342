import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/system';

export interface GroupSwitchProps {
  switchValues: { title: string; subtitle: string; checked?: boolean }[];
  onSwitchChange: (switchValue: boolean) => void;
}

interface GroupSwitchStyleComponentProps extends SwitchProps {
  values: { title: string; subtitle: string; checked?: boolean }[];
}

const GroupSwitchStyleComponent = styled(Switch)<GroupSwitchStyleComponentProps>(({ values }) => ({
  width: '230px',
  height: '40px',
  padding: '0',
  borderRadius: '20px',
  '&:focus': {
    backgroundColor: 'red',
  },
  '& .MuiSwitch-switchBase': {
    top: '-2px',
    left: '-2px',
    padding: 1,
    '& + .MuiSwitch-track': {
      backgroundColor: '#F5F5F5 !important',
      opacity: 1,
      border: '1px solid #5F5F5F',
    },
    '&.Mui-checked': {
      transform: 'translateX(115px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
      '& .MuiSwitch-thumb': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="121.5"><text font-size="12px" font-weight="400" font-family="verdana" x="32" y="18" fill="white">${
          values[1].title ? values[1].title : 'Title 2'
        }</text><text font-size="12px" font-weight="400" font-family="verdana" x="27" y="35" fill="white">${
          values[1].subtitle ? values[1].subtitle : 'Subtitle 2'
        }</text></svg>')`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#4976BA',
    width: '121.5px',
    height: '40px',
    borderRadius: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="121.5"><text style="font-size: 12px; font-weight: 400;" font-family="verdana" x="37" y="26" fill="white">${
      values[0].title ? values[0].title : 'Title1'
    }</text></svg>')`,
  },
  '& .MuiSwitch-track': {
    background: '#F5F5F5',
    opacity: '1 !important',
    borderRadius: '20px',
    border: '1px solid gray',
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: 'black',
      textAlign: 'center',
    },
    '&:before': {
      content: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="121.5"><text font-weight="400" font-size="12px" font-family="verdana" x="32" y="27" fill="black">${
        values[0].title ? values[0].title : 'Title1'
      }</text></svg>')`,
      left: 4,
    },
    '&:after': {
      content: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="121.5"><text font-size="12px" font-weight="400" font-family="verdana" x="33" y="19" fill="black">${
        values[1].title ? values[1].title : 'Title 2'
      }</text><text font-size="12px" font-weight="400" font-family="verdana" x="28" y="36" fill="slategray">${
        values[1].subtitle ? values[1].subtitle : 'Subtitle 2'
      }</text></svg>')`,
      right: 4,
    },
  },
}));

const GroupSwitch = ({ switchValues, onSwitchChange }: GroupSwitchProps): React.JSX.Element => (
  <GroupSwitchStyleComponent
    values={switchValues}
    data-testid="groupSwitch"
    name="switch"
    sx={{ m: 1 }}
    inputProps={{ 'aria-label': 'Group Switch' }}
    checked={switchValues[1].checked}
    onChange={(event: { target: { checked: boolean } }) => onSwitchChange(event.target.checked)}
  />
);

export default GroupSwitch;
