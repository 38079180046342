import { useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Box, ListItem } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { colors, H3, H4, H5, Text } from '@digitalportal-ui/core';
import { useState, useEffect } from 'react';
import ConfirmAndPaySummary from '../../organisms/ConfirmAndPaySummary/ConfirmAndPaySummary';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { TStore } from '../../../redux/store';
import { updatePolicyNumber, updatePolicyPayed } from '../../../redux/slices/policy/policy.slice';
import Routes from '../../../enums/routes';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { MainWrapper, MainContentWrapper } from '../paymentDetails/styles';
import DirectDebitIcon from '../../../assets/svg/direct_debit.svg?react';
import { StyledLink } from '../../atoms/Link/Link';
import {
  StyledList,
  LinkWrapper,
  RadioContainer,
  Section,
  SectionWithLogo,
  Wrapper,
  PageIntroductionWrapper,
} from './styles';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import RadioVariants from '../../../enums/radioVariants';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import { ErrorFocusOnNext } from '../../../lib/utils/errorFocusOnNext';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import { DirectDebitInstructionsValidation } from './validationSchema';
import { quoteToPolicy } from '../../../lib/utils/services/policyService';

export default function DirectDebitInstructions(): React.JSX.Element {
  const dispatch = useDispatch();
  const initialValues = useSelector((state: TStore) => state.quoteState.quote);
  const policyState = useSelector((state: TStore) => state.policyState.policy);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmErrorClose, setConfirmErrorClose] = useState(false);

  const paymentErrorOptions: NavigateOptions = {
    state: {
      usedRouter: true,
      title: 'We’re having problems setting up your policy',
      body: `Please try again or call us and use your quote number: ${initialValues.quoteNumber}`,
      route: Routes.paymentDetails,
      fromRoute: Routes.directDebitInstruction,
    },
  };
  function Submitter() {
    const { submitForm } = useFormikContext<{ confirm: string }>();
    useCustomEventListener('pageNavigationNext', () => {
      setConfirmErrorClose(false);
      submitForm();
    });

    return null;
  }

  const initialFormValues = {
    confirm: null,
  };

  useCustomEventListener('pageNavigationBack', () => {
    history(Routes.directDebit, navigateOptions);
  });

  useEffect(() => {
    if (policyState.policyPayed) {
      history(Routes.triage);
    }
  }, []);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageIntroductionWrapper>
          <PageIntroduction title="Are your details correct?" titleTestId="title" includeWrapper={false} />
        </PageIntroductionWrapper>
        <MainWrapper mb={15}>
          <MainContentWrapper>
            <Wrapper>
              <Box width="100%" overflow="hidden" textAlign="left">
                <Section>
                  <H3 bold>Please check your details</H3>
                </Section>
                <Section>
                  <H4 bold>Please check the following details are correct</H4>
                </Section>
                <Section>
                  <Text className="axa-mask" margin="10px 0">
                    Account holder name:&nbsp;
                    {policyState.paymentMethodDetails.directDebit?.accountName}
                  </Text>
                  <Text className="axa-mask" margin="10px 0">
                    Sort code: &nbsp;
                    {policyState.paymentMethodDetails.directDebit?.sortCode}
                  </Text>
                  <Text className="axa-mask" margin="10px 0">
                    Account number: &nbsp;
                    {policyState.paymentMethodDetails.directDebit?.accountNumber}
                  </Text>
                </Section>
                <H4 bold>Are these details correct?</H4>
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={DirectDebitInstructionsValidation}
                  validateOnMount
                  onSubmit={async () => {
                    setLoading(true);
                    try {
                      if (!policyState.policyPayed) {
                        await quoteToPolicy(policyState).then((resp) => {
                          dispatch(updatePolicyNumber(resp.policyNumber));
                        });
                        dispatch(updatePolicyPayed(true));
                      }
                      history(Routes.paymentSuccess, navigateOptions);
                      setLoading(false);
                    } catch (error) {
                      history(Routes.unhappyPath, paymentErrorOptions);
                      setLoading(false);
                    }
                  }}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <RadioContainer>
                        <Box display="contents">
                          <RadioBlock
                            isErrored={errors.confirm !== undefined && (touched.confirm || values.confirm === 1)}
                            variant={RadioVariants.dot}
                            name="confirm"
                            value={0}
                            checkedColor={colors.oceanBlue}
                          >
                            <Text>Yes</Text>
                          </RadioBlock>
                        </Box>
                        <Box display="contents">
                          <RadioBlock
                            isErrored={errors.confirm !== undefined && (touched.confirm || values.confirm === 1)}
                            variant={RadioVariants.dot}
                            name="confirm"
                            value={1}
                            checkedColor={colors.oceanBlue}
                          >
                            <Text>No</Text>
                          </RadioBlock>
                        </Box>
                      </RadioContainer>
                      {errors.confirm && (touched.confirm || values.confirm === 1) && !confirmErrorClose && (
                        <Box sx={{ marginBottom: { md: '16px' } }}>
                          <ErrorMessageBox
                            message={
                              values.confirm === 1
                                ? 'Please use the link below to update your payment details'
                                : undefined
                            }
                            closeError={setConfirmErrorClose}
                          />
                        </Box>
                      )}
                      {values.confirm === 1 && (
                        <LinkWrapper>
                          <StyledLink
                            onClick={(e) => {
                              e.preventDefault();
                              history(Routes.directDebit, navigateOptions);
                            }}
                            $marginTop="0"
                            to="/direct-debit"
                          >
                            Please update your payment details
                          </StyledLink>
                        </LinkWrapper>
                      )}
                      <Submitter />
                      <ErrorFocusOnNext setToTouched={errors.confirm !== undefined} />
                    </Form>
                  )}
                </Formik>
                <Section marginTop="40px">
                  <H3 bold>Confirming your Direct Debit</H3>
                </Section>
                <StyledList dense>
                  <ListItem>
                    <Text>We’ll set up your Direct Debit within the next 10 working days.</Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      Then we’ll confirm your Direct Debit instruction to you 10 working days before we collect your
                      first payment.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      If we change the frequency or amount of your payment, we’ll let you know 5 working days before we
                      collect it.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      AXA Health Limited will be collecting your payment, so you’ll see this name on your bank
                      statements.
                    </Text>
                  </ListItem>
                </StyledList>
                <SectionWithLogo>
                  <H3 bold>The Direct Debit Guarantee</H3>
                  <DirectDebitIcon />
                </SectionWithLogo>
                <H5 bold marginBottom="16px">
                  The Direct Debit Scheme is protected by the Direct Debit Guarantee
                </H5>
                <StyledList dense>
                  <ListItem>
                    <Text>
                      This Guarantee is offered by all banks and building societies that accept instructions to pay
                      Direct Debits.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      If there are any changes to the amount, date or frequency of your Direct Debit AXA Health Limited
                      will notify you 5 working days in advance of your account being debited or as otherwise agreed. If
                      you request AXA Health Limited to collect a payment, confirmation of the amount and date will be
                      given to you at the time of request
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      If an error is made in the payment of your Direct Debit, by AXA Health Limited or your bank or
                      building society, you are entitled to a full and immediate refund of the amount paid from your
                      bank or building society. If you receive a refund you are not entitled to, you must pay it back
                      when AXA Health Limited asks you to
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text>
                      You can cancel a Direct Debit at any time by simply contacting your bank or building society.
                      Written confirmation may be required. Please also notify us.
                    </Text>
                  </ListItem>
                </StyledList>
                <Section $noMargin>
                  <Text margin="10px 0" semibold>
                    AXA Health’s contact details
                  </Text>
                  <Text margin="10px 0">Call us on {phoneNumber}</Text>
                  <Text margin="10px 0">Email information@axahealth.co.uk</Text>
                  <Text margin="10px 0">Write to us at FREEPOST AXA HEALTH PLAN</Text>
                </Section>
              </Box>
            </Wrapper>

            <PageNavigation loading={loading} />
          </MainContentWrapper>
          <ConfirmAndPaySummary
            cardData={initialValues}
            isMonthlyShown={policyState.paymentFrequency === 'Monthly'}
            regularPaymentDate={policyState.regularPaymentDay}
          />
        </MainWrapper>
      </Main>
    </>
  );
}
